import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import DataV from '@jiaminghi/data-view'
import { deleteCofirm } from "@/utils/confirm";


import dialogDrag from './directive/dialogDrag'
import waves from './directive/waves'
Vue.directive('dialogDrag', dialogDrag)
Vue.directive('waves', waves)

Vue.use(ElementUI)
// Vue.use(ElementUI, { size: 'small', zIndex: 3000 });

Vue.use(DataV)

Vue.config.productionTip = false
// process.env.VUE_APP_MOCK === 'mock' && require('@/mock')

// 全局方法 删除确认提示
Vue.prototype.deleteCofirm = deleteCofirm

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
