<template>
<div id="app">
    <!-- <div id="nav" v-if="navVisible">
        <router-link to="/whyz/meeting">会议管理</router-link> |
        <router-link to="/whyz/device-list">设备列表</router-link> |
        <router-link to="/whyz/pass-rules">通行规则</router-link> |
        <router-link to="/whyz/visitor">通行/陌生人记录</router-link> |
        <router-link to="/whyz/visitor-manage">访客管理</router-link>
    </div>
    <el-button size="mini" icon="el-icon-more" circle @click="onClose" class="close-btn" /> -->
    <router-view />
</div>
</template>

<script>
export default {
    data() {
        return {
            navVisible: true,
        }
    },
    methods: {
        onClose() {
            this.navVisible = !this.navVisible
        },
    },
}
</script>

<style lang="scss">
.v-modal {
    background: #fff;
}

#nav {
    background: #42b983;
    // position: fixed;
    // left: 0;
    // right: 0;
    // top: 0;
    // z-index: 99998;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    padding: 15px;
}

.v-modal {
    background: #fff;
}

.close-btn {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #ce3f3f;
}

.main {
    padding: 15px;
    background: #fff;
}

.el-header {
    // background-color: #b3c0d1;
    height: auto !important;
    padding: 0;
}

.el-main {
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 0;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.pagination-box {
    text-align: center;
    margin-top: 15px;
}

.dialog-title {
    font-size: 17px;
    font-weight: 600;
    text-align: center;
}

.footer-btn {
    width: 100px;
    letter-spacing: 4px;
}

.footer-btn-cancel {
    margin-left: 50px;
}

.el-message-box {
    margin-bottom: 350px;
    z-index: 5000 !important;
}

.el-message-box__wrapper {
    z-index: 4999 !important;
}

.el-table thead {
    color: rgb(88, 88, 88);
    // font-size: 15px;
}

.el-tabs__item {
    width: 60%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
}
</style>
