import common from './common'
import pass from './pass'
import axios from 'axios'
import router from '../router/index'

axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded'

// 请求拦截器，在请求发到接口之前
axios.interceptors.request.use(
  function(config) {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 响应拦截器，在响应回到页面之前
axios.interceptors.response.use(
  function(response) {
    return response.data
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token')
      router.push('/auth').catch(() => {})
    }

    return Promise.reject(new Error('网络请求异常'))
  }
)

const api = {
  common,
  pass
}

export default api
