import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../views/Auth.vue'
import { getAllUrlParams } from '../utils/index'
import api from '../api'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Auth
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/whyz/room-manage',
    name: 'RoomManage',
    component: () =>
      import(/* webpackChunkName: "meeting" */ '../views/RoomManage.vue')
  },
  {
    path: '/whyz/meeting',
    name: 'MeetingTabs',
    component: () =>
      import(/* webpackChunkName: "meeting" */ '../views/MeetingTabs.vue')
  },
  {
    path: '/whyz/meeting-manage',
    name: 'MeetingManage',
    component: () =>
      import(/* webpackChunkName: "meeting" */ '../views/MeetingManage.vue')
  },
  {
    path: '/whyz/meeting-statistics',
    name: 'MeetingStatistics',
    component: () =>
      import(/* webpackChunkName: "meeting" */ '../views/MeetingStatistics.vue')
  },
  {
    path: '/whyz/device-list',
    name: 'DeviceList',
    component: () =>
      import(/* webpackChunkName: "device" */ '../views/DeviceList.vue')
  },
  {
    path: '/whyz/pass-rules',
    name: 'PassRules',
    component: () =>
      import(/* webpackChunkName: "device" */ '../views/PassRules.vue')
  },
  {
    path: '/whyz/pass-logs',
    name: 'PassLogs',
    component: () =>
      import(/* webpackChunkName: "device" */ '../views/PassLogs.vue')
  },
  {
    path: '/whyz/stranger-logs',
    name: 'StrangerLogs',
    component: () =>
      import(/* webpackChunkName: "device" */ '../views/StrangerLogs.vue')
  },
  {
    path: '/whyz/visitor-manage',
    name: 'VisitorManage',
    component: () =>
      import(/* webpackChunkName: "visitor" */ '../views/VisitorManage.vue')
  },
  {
    path: '/whyz/visitor',
    name: 'VisitorTabs',
    component: () =>
      import(/* webpackChunkName: "visitor" */ '../views/VisitorTabs.vue')
  },
  {
    path: '/whyz/meeting-view',
    name: 'meetingView',
    component: () =>
      import(/* webpackChunkName: "meeting" */ '../views/meetingView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const requestid = getAllUrlParams(window.location.href).requestid
  // console.log('requestid:', requestid)
  // console.log('path:', to.path)
  if (to.path === '/auth' || to.path === '/whyz/meeting-view') {
    next()
  } else {
    // if (localStorage.getItem('token')) {
    //   next()
    // } else {
    api.common
      .auth({ request_id: requestid })
      .then(res => {
        if (res.code === 0) {
          const token = res.data.token
          localStorage.setItem('token', token)
          next()
        } else {
          next('/auth').catch(() => {})
        }
      })
      .catch(() => {
        next('/auth')
      })
    }
  // }
})

export default router
