<template>
    <div class="auth-wrap">
        <el-result icon="error" title="错误提示" subTitle="发生错误，请刷新页面重试">

        </el-result>
    </div>
</template>

<script>


export default {
    data() {
        return {}
    },
    mounted(){
    }
}
</script>

<style lang='scss' scoped>
.auth-wrap {
    text-align: center;
    padding: 20px;
    h1 {
        font-size: 16px;
    }
    p {
        display: inline-block;
        margin: 0 20px;
    }
}
</style>>
