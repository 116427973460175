import axios from 'axios'
export default {
  deptQuery(data) {
    return axios.post('/v100/school/dept/query', data)
  },
  getAllUserByDept(data) {
    return axios.post('/v100/school/dept/user/query', data)
  },
  getFaceUserByDept(data) {
    return axios.post('/v100/school/dept/user/face/query', data)
  },
  pushUserToDevice(data) {
    return axios.post('/v100/school/device/push/user', data)
  },
  auth(data) {
    return axios.post('/v100/school/user/auth', data)
  }
}
