import axios from 'axios'
export default {
  getDevice(data) {
    return axios.post('/v100/school/device/query', data)
  },
  getAllDevice(data) {
    return axios.post('/v100/school/device/all/query', data)
  },
  findDevice(data) {
    return axios.post('/v100/school/device/find', data)
  },
  updateDevice(data) {
    return axios.post('/v100/school/device/update', data)
  },
  processDevice(url, data) {
    return axios.post(url, data)
  },
  queryDeviceLog(data) {
    return axios.post('/v100/school/device/log/query', data)
  },
  getRuleList(data) {
    return axios.post('/v100/school/access/rule/query', data)
  },
  createRule(data) {
    return axios.post('/v100/school/access/rule/create', data)
  },
  deleteRule(data) {
    return axios.post('/v100/school/access/rule/delete', data)
  },
  updateRule(data) {
    return axios.post('/v100/school/access/rule/update', data)
  },
  updateRuleDevice(data) {
    return axios.post('/v100/school/access/rule/device/update', data)
  },
  updateRuleUser(data) {
    return axios.post('/v100/school/access/rule/user/update', data)
  },
  queryRuleDevice(data) {
    return axios.post('/v100/school/access/rule/device/query', data)
  },
  queryRuleUser(data) {
    return axios.post('/v100/school/access/rule/user/query', data)
  },
  queryPassRecord(data) {
    return axios.post('/v100/school/device/record/query', data)
  },
  maskDetection(data) {
    return axios.post('/v100/school/device/mask/detection', data)
  },
  strangerLogs(data) {
    return axios.post('/v100/school/device/record/stranger/baby/query', data)
  },
  visitorLogs(data) {
    return axios.post('/v100/school/visitor/query', data)
  },
  visitorUpdate(data) {
    return axios.post('/v100/school/visitor/update', data)
  },

  // 新增会议室
  meetRoomCreate(data) {
    return axios.post('/v100/school/meet/room/create', data)
  },
  // 删除会议室
  meetRoomDelete(data) {
    return axios.post('/v100/school/meet/room/delete', data)
  },
  // 更新会议室
  meetRoomUpdate(data) {
    return axios.post('/v100/school/meet/room/update', data)
  },
  // 查询会议室
  meetRoomQuery(data) {
    return axios.post('/v100/school/meet/room/query', data)
  },
  // 查询会议室设备
  meetDeviceQuery(data) {
    return axios.post('/v100/school/meet/device/query', data)
  },
  // 推送会议人员到设备
  meetUserPushDevice(data) {
    return axios.post('/v100/school/meet/push/user/device', data)
  },
  // 房间设备绑定
  meetRoomBindDevice(data) {
    return axios.post('/v100/school/meet/room/bind/device', data)
  },
  // 房间设备取消绑定
  meetRoomUnbindDevice(data) {
    return axios.post('/v100/school/meet/room/unbind/device', data)
  },

  // 创建会议
  meetCreate(data) {
    return axios.post('/v100/school/meet/create', data)
  },
  // 取消会议
  meetCancel(data) {
    return axios.post('/v100/school/meet/cancel', data)
  },
  // 修改会议
  meetUpdate(data) {
    return axios.post('/v100/school/meet/update', data)
  },
  // 查询会议
  meetQuery(data) {
    return axios.post('/v100/school/meet/query', data)
  },
  // 查询会议详情
  meetDetailQuery(data) {
    return axios.post('/v100/school/meet/details/query', data)
  },

  // 查询会议记录列表
  meetRecordQuery(data) {
    return axios.post('/v100/school/meet/record/query', data)
  },

  // 会议统计
  meetStatisticsQuery(data) {
    return axios.post('/v100/school/meet/statistics/query', data)
  },

  deviceList() {
    return axios.get('/api/device-list')
  },
  ruleList() {
    return axios.get('/api/rule-list')
  },
  logList() {
    return axios.get('/api/log-list')
  }
}
