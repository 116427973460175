import { MessageBox } from 'element-ui'

export function deleteCofirm(text = '确定执行此操作吗？', type = 'warning', confirmButtonText = '删除') {
    return MessageBox.confirm(text, '提示', {
        type: type,
        confirmButtonText: confirmButtonText,
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
            if (action === "confirm") {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = `${confirmButtonText}中...`;
                setTimeout(() => {
                    done();
                    instance.confirmButtonLoading = false;
                }, 500);
            } else {
                done();
            }
        },
    })
}